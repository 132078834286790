/* eslint-disable */
export default {
  name: 'nda',
  description: 'Non-Disclosure Agreement',
  start: 'single_0',
  kind: 'Form',
  step: 7,
  nodes: [
    {
      id: 'single_0',
      kind: 'Single',
      content: {
        question: 'Does the confidential information consist of the location of certain real estate?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'description_1' },
          { kind: 'choice', label: 'No', next: 'description_0' },
        ],
      },
    },
    {
      id: 'description_0',
      kind: 'Description',
      content: {
        fields: [{ value: 'This form is designed for use by a company or individual (referred to as the “Disclosing Party”) who is asked to provide confidential information to an individual or company (referred to as the “Recipient”).' }],
        next: 'input_1'
      }
    },
    {
      id: 'input_1',
      kind: 'Input',
      content: {
        question: 'What is the name of the Disclosing Party?',
        fields: [
          { kind: 'text', store: 'disclosing_party' }
        ],
        next: 'single_2',
      }
    },
    {
      id: 'single_2',
      kind: 'Single',
      content: {
        question: 'Is Disclosing Party an individual or an entity?',
        fields: [
          { kind: 'choice', label: 'Individual' },
          { kind: 'choice', label: 'Entity' },
        ],
        store: 'disclosing_party_type',
        next: 'select_3',
      },
    },
    {
      id: 'select_3',
      kind: 'Input',
      content: {
        question: 'In what state is Disclosing Party primarily located?',
        fields: [
          { kind: 'select', datasource: 'state', store_value: 'name' },
        ],
        store: 'choice_of_low_state',
        next: 'input_4',
      }
    },
    {
      id: 'input_4',
      kind: 'Input',
      content: {
        question: "What is the name of the Recipient?",
        fields: [
          { kind: 'text', store: 'recipient' },
        ],
        next: 'single_5',
      }
    },
    {
      id: 'single_5',
      kind: 'Single',
      content: {
        question: 'Is Recipient an individual or an entity?',
        fields: [
          { kind: 'choice', label: 'Individual' },
          { kind: 'choice', label: 'Entity' },
        ],
        store: 'recipient_type',
        next: 'input_6',
      },
    },
    {
      id: 'input_6',
      kind: 'Input',
      content: {
        question: 'Please provide a brief description of the information to be shared.',
        description: `Following are some examples of the description of confidential information:
        •	The structure of a real estate, business or financing transaction.
        •	The description of a proprietary formula.
        •	The description of a proprietary software application.`,
        fields: [
          { kind: 'textarea', store: 'description', rows: "5" },
        ],
        next: 'final',
      },
    },
    {
      id: 'final',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'nda',
      }
    },
    {
      id: 'description_1',
      kind: 'Description',
      content: {
        fields: [{ value: 'This form is designed for use by a company or individual (referred to as the “Owner”) who is asked to provide to an individual or company (referred to as the “Recipient”), the location of real property which the Owner wishes to keep confidential.' }],
        next: 'input_1_1'
      }
    },
    {
      id: 'input_1_1',
      kind: 'Input',
      content: {
        question: 'What is the name of the owner?',
        fields: [
          { kind: 'text', store: 'owner_name' }
        ],
        next: 'single_1_2',
      }
    },
    {
      id: 'single_1_2',
      kind: 'Single',
      content: {
        question: 'Is the Owner an individual or an entity?',
        fields: [
          { kind: 'choice', label: 'Individual' },
          { kind: 'choice', label: 'Entity' },
        ],
        store: 'owner_type',
        next: 'select_1_3',
      },
    },
    {
      id: 'select_1_3',
      kind: 'Input',
      content: {
        question: 'In what state is the property located?',
        fields: [
          { kind: 'select', datasource: 'state', store_value: 'name' },
        ],
        store: 'property_state',
        next: 'input_1_4',
      }
    },
    {
      id: 'input_1_4',
      kind: 'Input',
      content: {
        question: 'What is the name of the Recipient?',
        fields: [
          { kind: 'text', store: 'recipient_name' }
        ],
        next: 'single_1_5',
      }
    },
    {
      id: 'single_1_5',
      kind: 'Single',
      content: {
        question: 'Is Recipient an individual or an entity?',
        fields: [
          { kind: 'choice', label: 'Individual' },
          { kind: 'choice', label: 'Entity' },
        ],
        store: 'recipient_type',
        next: 'final_1',
      },
    },
    {
      id: 'final_1',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'nda_2',
      }
    },
  ],
}
