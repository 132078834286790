export default {
  name: 'workerscomp',
  description: "Do you need to provide workers' compensation insurance and other California employee benefits?",
  start: 'description_1',
  kind: 'Topic',
  attach: {
    EXP_1:
      'Workers who are considered employees under California law are entitled to workers’ compensation insurance (Cal. Labor Code §3700), unemployment insurance (Cal. Unemployment Ins. Code §976) and, in certain cases, the protection of California wage orders (Cal. Labor Code §1173). California wage orders establish minimum wage, sick leave, overtime and other employee protections. For additional information on California wage orders, click <a href="#exp_1_1">here</a>.<description id="exp_1_1">{{EXP_1_1}}<a href="#">Close</a></description> In addition, the employer must withhold estimated state personal income tax from their employees’ wages. (Cal. Unemployment Ins. Code §13020) If workers’ compensation insurance is required, the employer must obtain such insurance from either a licensed insurance company or through the State Compensation Insurance Fund; it also has the option to self-insure for workers’ compensation. California recently enacted a new law effective January 1, 2020 (called AB5) which changes the rules for classifying workers as either employees or independent contractors. For information about how these California new rules apply to a particular worker, select the applicable response in answer to the following questions. You can always click on the back button to change your answer or explore the legal consequences of different selections. Click on <strong>Continue</strong> to proceed.',
    EXP_1_1: `Certain California wage orders apply to all California employees. For example, the temperature maintained in the work area of all California employees “shall provide reasonable comfort consistent with industry-wide standards for the nature of the process and the work performed.” (Industrial Welfare Commission Order No. 4-2001, §15(A)) By contrast, California minimum wage, overtime and meal and rest period requirements apply only to those employees who are not “employed in administrative, executive or professional capacities.” (Id., §1(A)) For a definition of “administrative, executive and professional” click <a href='https://www.dir.ca.gov/iwc/Wage_orders_January2001/IWCArticle4.html' target='_blank'>here</a> to view the wage order.`,
    EXP_2: `Sole proprietors are not employees because they do not work for someone else. Accordingly, sole proprietors are not required to be obtain workers’ compensation insurance or unemployment insurance for themselves. However, they have the option to purchase such insurance to cover themselves. (See Cal. Unemployment Ins. Code §708(b)) For some frequently asked questions about workers’ compensation for employers, click <a href='https://www.dir.ca.gov/dwc/faqs.html' target='_blank'>here</a>.`,
    EXP_3:
      `A working owner who is a partner in a partnership or a member of an LLC may be an employee for certain state law purposes but not others. There are four rules governing the treatment of partners in partnerships and members of LLCs as follows:<br/>
      1. In the case of a partnership or an LLC treated as a partnership for federal income tax purposes, none of its members are considered employees under California law for purposes of unemployment insurance (Cal. Unemployment Ins. Code §623) or for purposes of California state income tax withholding requirements. (Id. at §13004.6)<br/>
      2. All members of an LLC that is treated as a corporation for federal income tax purposes are considered statutory employees under California law. ((Cal. Unemployment Ins. Code §621(f)) Accordingly, such members must be covered by unemployment insurance and the LLC must withhold estimated state income tax from their wages to the extent such wages are deductible on the LLC’s federal income tax return. (Id. at §13009)<br/>
      3. By contrast, “all working members of a partnership or LLC receiving wages irrespective of profits from the partnership or LLC” must be covered by workers’ compensation insurance unless they are general partners of a partnership or managing members of an LLC who sign a written waiver as set forth in Rule 4 below. (Cal. Labor Code §3351(f))<br/>
      4. If a general partner of a partnership or a managing member of an LLC signs a written waiver of his or her rights to workers’ compensation benefits, the business is not required to carry workers’ compensation insurance for such worker. (Cal. Labor Code §3352(a)(17)`,
    EXP_4: `While the shareholders of a corporation may be considered employees of the corporation if the corporation controls the details of the worker’s activities, a professional corporation is not required to carry workers’ compensation insurance for its sole owner or for any owner who is a practitioner performing professional services under the corporation&#39;s license who executes a waiver of all rights to workers’ compensation benefits. (Cal. Labor Code 3352(a)(18)) To determine whether the shareholders are otherwise considered employees under California law, click on the <strong>Continue</strong> button below.`,
    EXP_5:
      `For purposes of California workers’ compensation insurance requirements, all officers and directors of corporations are considered employees of the corporations “while rendering actual service for the corporations for pay.” (Cal. Labor Code §3351(c)) However, a corporation is not required to carry workers’ compensation insurance for an officer or director who owns at least 10% of the stock of the corporation and signs a waiver. (Cal. Labor Code §3352(a)(16)) For other state law purposes, all officers of a corporation are considered statutory employees under California law. (Cal. Unemployment Ins. Code §621(a)) Accordingly, corporate officers must be covered by unemployment insurance and the corporation must withhold estimated state income tax from their wages. (Id. at §13009) By contrast, for state law purposes (other than workers’ compensation insurance requirements), a director of a corporation is not considered an employee when performing services in his or her capacity as a director. (Cal. Unemployment Ins. Code §622)`,
    EXP_6: `California has two distinct tests for employment. The strictest test, called the ABC test, is more likely to result in finding that the worker is an employee under California law. The workers described below are excluded from the ABC test and are a subject to a more lenient test, called the Borello test.
      <ul>
        <li>A California licensed insurance agent, surplus line broker or life insurance analyst.</li>
        <li>A California licensed physician, surgeon, dentist, podiatrist, psychologist or veterinarian performing health care services.</li>
        <li>A California licensed lawyer, architect, engineer, private investigator or accountant performing the services for which he or she is licensed.</li>
        <li>A representative of a securities broker-dealer or investment advisor, who is registered with the SEC or FINRA or is licensed by the State of California.</li>
        <li>A commercial fisherman working on an American vessel.  </li>
        <li>A newspaper distributor working under contract with a newspaper publisher or a paperboy.</li>
        <li>A marketing service provider, travel agent, graphic designer, grant writer, fine artist, enrolled agent, payment processing agent, photographer, freelance writer, or newspaper cartoonist who in each case meets certain requirements. Click <a href="#exp_6_0_1">here</a><description id="exp_6_0_1">{{EXP_6_0_1}}<a href="#">Close</a></description> for a description of these requirements.</li>
        <li>A licensed esthetician, manicurist, barber, or cosmetologist, who meets certain requirements. Click <a href="#exp_6_0_2">here</a><description id="exp_6_0_2">{{EXP_6_0_2}}<a href="#">Close</a></description> for a description of these requirements.</li>
        <li>The owner of a business that provides services to other businesses and satisfies certain other requirements. Click <a href="#exp_6_0_3">here</a><description id="exp_6_0_3">{{EXP_6_0_3}}<a href="#">Close</a></description> for a description of the other requirements.</li>
        <li>A subcontractor who is licensed by the Contractors State License Board performs work under such license and meets certain other requirements. Click <a href="#exp_6_0_4">here</a><description id="exp_6_0_4">{{EXP_6_0_4}}<a href="#">Close</a></description> for a description of the other requirements.</li>
      </ul>
    `,
    EXP_6_0_1: `
      <strong><u>Contract for Professional Services</u></strong><br/>
      The other requirements are:<br/>
      <ul>
        <li>The worker maintains a business location, which may include their residence, that is separate from their company.</li>
        <li>The worker has a business license, in addition to any required professional licenses or permits for the individual to practice in their profession.</li>
        <li>The worker has the ability to set or negotiate their own rates for the services performed.</li>
        <li>Outside of project completion dates and reasonable business hours, the worker has the ability to set the individual’s own hours.</li>
        <li>The worker is customarily engaged in the same type of work performed under contract with another hiring entity or holds themselves out to other potential customers as available to perform the same type of work.</li>
        <li>The worker customarily and regularly exercises discretion and independent judgment in the performance of the services.</li>
        <li>If the worker is a photographer, freelance writer or newspaper cartoonist, the worker does not submit his or her work to the company more than 35 times per year. (See Cal. Labor Code §2750.3(c))]</li>
      </ul>
    `,
    EXP_6_0_2: `
      <strong><u>Contract for Professional Services – Personal Care</u></strong><br/>
      The requirements are:<br/>
      <ul>
        <li>The worker sets their own rates, processes their own payments, and is paid directly by the clients.</li>
        <li>The worker sets their own hours of work and has sole discretion to decide the number of clients and which clients for whom they will provide services.</li>
        <li>The worker has their own book of business and schedules their own appointments.</li>
        <li>The worker maintains their own business license for the services offered to clients.</li>
        <li>If the worker is performing services at the company’s location, then the worker issues a Form 1099 to the company for payment of rent to the company. (See Cal. Labor Code §2750.3(c)(xi))]</li>
      </ul>
    `,
    EXP_6_0_3: `
      <strong><u>Contract for Business Services</u></strong><br/>
      The other requirements are:<br/>
      <ul>
        <li>The worker is free from the control and direction of the company in connection with the performance of the work, both under the contract for the performance of the work and in fact.</li>
        <li>The worker is providing services directly to the company rather than to customers of the company.</li>
        <li>The contract with the worker is in writing.</li>
        <li>If the work is performed in a city or county that requires the worker to have a business license or business tax registration, the worker has the required business license or business tax registration.</li>
        <li>The worker maintains a business location that is separate from the business or work location of the company.</li>
        <li>The worker is customarily engaged in an independently established business of the same nature as that involved in the work performed.</li>
        <li>The worker actually contracts with other businesses to provide the same or similar services and maintains a clientele without restrictions from the company.</li>
        <li>The worker advertises and holds themself out to the public as available to provide the same or similar services.</li>
        <li>The worker provides their own tools, vehicles, and equipment to perform the services.</li>
        <li>The worker can negotiate their own rates.</li>
        <li>Consistent with the nature of the work, the worker can set their own hours and location of work.</li>
        <li>The worker is not performing the type of work for which a contractor’s license is required. (See Cal. Labor Code §2750.3(e)]</li>
      </ul>
    `,
    EXP_6_0_4: `
      <strong><u>Subcontractors</u></strong><br/>
      This exception applies to a company that is a licensed contractor who has hired a subcontractor. The subcontractor’s employment status will be tested under the more lenient employment test so long as the following requirements are met:<br/>
      <ul>
        <li>The subcontract is in writing.</li>
        <li>The subcontractor is licensed by the Contractors State License Board and the work is within the scope of that license*.</li>
        <li>If the subcontractor’s place of business is in a city or county that requires the subcontractor to have a business license or business tax registration, the subcontractor has the required business license or business tax registration.</li>
        <li>The subcontractor maintains a business location that is separate from the business or work location of the company.</li>
        <li>The subcontractor has the authority to hire and to fire other persons to provide or to assist in providing the services.</li>
        <li>The subcontractor assumes financial responsibility for errors or omissions in labor or services as evidenced by insurance, legally authorized indemnity obligations, performance bonds, or warranties relating to the labor or services being provided.</li>
        <li>The subcontractor is customarily engaged in an independently established business of the same nature as that involved in the work performed. (See Cal. Labor Code §2750.3(f)</li>
      </ul>
      (* Note that special rules apply to subcontractors providing construction trucking services. For details, see Cal. Labor Code §2750.3(f)(8))]
    `,
    EXP_6_1: `(*Or do the other Borello factors (discussed below) strongly point towards an employment relationship? In answering this question, please take the following into account. You have indicated that the worker in question falls within one of the groups that is subject to the so-called Borello test for employment status. The Borello test applies a number of factors, all of which must be considered and none of which is controlling by itself. The most significant factor is whether the employer has the right to control the worker both as to the work to be done and the manner and means in which it is performed. For a list of the other factors click <a href="#exp_6_1_1">here</a>.<description id="exp_6_1_1">{{EXP_6_1_1}}<a href="#">Close</a></description> If most of these other factors strongly point to an employment relationship, then the worker will likely be considered an employee under the Borello test even in the absence of control over the manner and means of the worker’s services, and you should answer “yes” to the question above.)`,
    EXP_6_1_1: `
      <strong><u>Other Factors for the Borello Test</u></strong><br/>
      This exception applies to a company that is a licensed contractor who has hired a subcontractor. The subcontractor’s employment status will be tested under the more lenient employment test so long as the following requirements are met:<br/>
      <ol>
        <li>Does the worker hold themself out as being engaged in an occupation or business distinct from that of the company?</li>
        <li>Is the work distinct from the business of the company?</li>
        <li>Does the worker supply the tools and the place for the worker doing the work?</li>
        <li>Has the worker invested in the business, such as in the equipment or materials required by their task?</li>
        <li>Does the service performed require a special skill?</li>
        <li>Is the work usually done free from the direction of the employer or by a specialist without supervision?</li>
        <li>Does the worker have an opportunity for profit or loss depending on their managerial skill?</li>
        <li>Are the services to be performed over a short period of time – that is, over days or weeks, rather than months or years?</li>
        <li>Is the worker paid by the job rather than by the hour? </li>
        <li>Does the worker hire their own employees?</li>
        <li>Does the worker have contractual protection against being fired by the company at will?</li>
        <li>Do the company and the worker believe that the worker is an independent contractor of the company rather than an employee? (See, S.G. Borello & Sons, Inc. v. Department of Industrial Relations, 48 Cal. 3d 341 (1989))      </li>
      </ol>
      If the answers to most of these questions is “no”, then the worker may be an employee of the company under the Borello test even though the company does not have the right to control the manner and means of the worker’s services.
    `,
    EXP_6_2: `For all purposes under California law except workers’ compensation insurance, a California licensed real estate salesperson is not considered the employee of the broker for whom he or she works as long as the following two requirements are met:<br/><br/>
    <ul>
      <li>Substantially all of the compensation for the services performed by the salesperson is directly related to sales rather than to the number of hours worked by the salesperson.</li>
      <li>The salesperson is working under a written contract with the broker, which provides that the salesperson will not be treated as an employee for state tax purposes. (Cal. Bus. & Prof. Code §10032(b); Cal. Unemployment Ins. Code §§650 and 13004.1)</li>
    </ul>Thus, a salesperson meeting the above two requirements is not entitled to unemployment insurance or to the benefit of California wage orders regulating salaries and working conditions, and his or her broker is not required to withhold estimated state personal income tax from the salesperson’s wages. However, for workers’ compensation purposes, the salesperson is generally considered to be an employee. (See Payne v. White House Props., Inc. 112 Cal. App. 3d 465 (1980)). Accordingly, a broker is generally required to provide workers compensation insurance coverage (but not other employment benefits) to its California licensed salespersons.
    `,
    EXP_6_3: `In September 2019, California enacted AB 5 which requires the application of the “ABC test” to determine if workers in California (excluding certain professionals, subcontractors, and business service providers) are employees or independent contractors for purposes of workers’ compensation insurance and other California employee benefits and protections. Following is a summary of how to apply this test as provided by the California Supreme Court (Dynamex Operations West, Inc. v. Superior Court, 4 Ca. 5th 903 (2018)) and the California Department of Industrial Relations.
    <strong>Part A: Is the worker free from the control and direction of the company in the performance of the work, both under the contract for the performance of the work and in fact?</strong>
    <ul>
      <li>The company must establish that the worker is free of such control to satisfy part A of the ABC test. </li>
      <li>A worker who is subject, either as a matter of contractual right or in actual practice, to the type and degree of control a business typically exercises over employees would be considered an employee. </li>
      <li>Depending on the nature of the work and overall arrangement between the parties, a business need not control the precise manner or details of the work in order to be found to have maintained the necessary control that an employer ordinarily possesses over its employees.     </li>
    </ul>
    <strong>PART B: Does the worker perform work that is outside the usual course of the company’s business</strong>
    <ul>
      <li>The company must establish that the worker performs work that is outside the usual course of its business in order to satisfy part B of the ABC test.</li>
      <li>Contracted workers who provide services in a role comparable to that of an existing employee will likely be viewed as working in the usual course of the company’s business.</li>
      <li>Examples where services are not part of the company’s usual course of business:
        <ul>
          <li>When a retail store hires an outside plumber to repair a leak in a bathroom on its premises.</li>
          <li>When a retail store hires an outside electrician to install a new electrical line.</li>
        </ul>
      </li>
      <li>Examples where services are part of the company’s usual course of business:
        <ul>
          <li>When a clothing manufacturing company hires work-at-home seamstresses to make dresses from cloth and patterns supplied by the company that will thereafter be sold by the company.</li>
          <li>When a bakery hires cake decorators to work on a regular basis on its custom-designed cakes.</li>
        </ul>
      </li>
    </ul>
    <strong>PART C: Is the worker customarily engaged in an independently established trade, occupation, or business of the same nature as the work performed for the company?</strong>
    <ul>
      <li>The company must prove that the worker is customarily and currently engaged in an independently established trade, occupation, or business.</li>
      <li>The company cannot unilaterally determine a worker’s status simply by assigning the worker the label “independent contractor” or by requiring the worker, as a condition of hiring, to enter into a contract that designates the worker an independent contractor. </li>
      <li>Part C requires that the independent business operation actually be in existence at the time the work is performed. The fact that it could come into existence in the future is not sufficient. </li>
      <li>An individual who independently has made the decision to go into business generally takes the usual steps to establish and promote that independent business. Examples of this include:
        <ul>
          <li>Incorporation, licensure, advertisements;</li>
          <li>Routine offerings to provide the services of the independent business to the public or to a number of potential customers, and the like.</li>
        </ul>
      </li>
      <li>If an individual’s work relies on a single employer, Part C is not met. For example, Part C was not satisfied where a taxi driver was required to hold a municipal permit that may only be used while that driver is employed by a specific taxi company. </li>
    </ul>`,
    EXP_6_4: 'You have indicated that the worker is an employee under the applicable employment test. Click continue to determine whether the worker is nonetheless exempt from the requirement of workers’ compensation insurance coverage.',
    EXP_6_5: 'You have indicated that the worker is not an employee under the applicable employment test. Click continue to determine whether the worker is nonetheless considered a “statutory employee” for certain purposes under California law.',
    EXP_7:
      'In order for a contractor to have its license qualified by a Responsible Managing Employee (RME), the RME must be a “bona fide employee” of the contractor. (Bus & Prof Code §7068(c)) Accordingly, the contractor must obtain workers’ compensation insurance and unemployment insurance for the RME and must withhold estimated state personal income tax from the RME’s wages. ',
    EXP_7_5: 'A company must carry workers’ compensation insurance if it holds an active Roofing (C-39) classification even if it has no employees. (Cal. Bus. & Prof. Code §7125(a) and §7125(b)(2))',
    EXP_8:
      'An unlicensed worker performing services for which a license is required is a statutory employee entitled to workers’ compensation insurance coverage (see Cal. Labor Code §2750.5) and unemployment insurance (Cal. Unemployment Ins. Code §621.5(a)) and his or her employer must withhold estimated state personal income tax from the worker’s wages. (Cal. Unemployment Ins. Code §13004.5(a))',
    EXP_9: `<ul>
      <li>A person who is employed by his or her parent, spouse, or child as a nanny or housekeeper.</li>
      <li>A person performing services in return for aid or sustenance only, received from any religious, charitable, or relief organization.</li>
      <li>A person performing voluntary services at or for a recreational camp, hut, or lodge operated by a tax-exempt nonprofit organization of which he or she or a member of his or her family is a member and who does not receive compensation for those services, other than meals, lodging, or transportation.</li>
      <li>A person performing voluntary service as a ski patrolman who does not receive compensation for those services, other than meals or lodging or the use of ski lifts.</li>
      <li>A person employed by a ski lift operator to work at a snow ski area who is relieved of, and is not performing any, prescribed duties, while participating in recreational activities on his or her own initiative.</li>
      <li>A person, other than a regular employee, participating in sports or athletics who does not receive compensation for the participation other than the use of athletic equipment, uniforms, transportation, travel, meals, lodgings, or other expenses incidental thereto.</li>
      <li>A person working as a nanny or housekeeper where the employment was, or was contracted to be, for less than 52 hours or was, or was contracted to be, for wages of not more than $100.</li>
      <li>A person performing voluntary service for a private, nonprofit organization who does not receive remuneration for the services, other than meals, transportation, lodging, or reimbursement for incidental expenses.</li>
      <li>A person, other than a regular employee, performing officiating services relating to amateur sporting events sponsored by a public agency or private, nonprofit organization, who does not receive remuneration for these services, other than a stipend for each day of service no greater than the amount established by the Department of Human Resources as a per diem expense for employees or officers of the state.</li>
      <li>A student participating as an athlete in amateur sporting events sponsored by a public agency or public or private nonprofit college, university, or school, who does not receive remuneration for the participation, other than the use of athletic equipment, uniforms, transportation, travel, meals, lodgings, scholarships, grants-in-aid, or other expenses incidental thereto.</li>
      <li>A person, other than a regular employee, performing services as a sports official for an entity sponsoring an intercollegiate or interscholastic sports event, or any person performing services as a sports official for a public agency, public entity, or a private nonprofit organization, which public agency, public entity, or private nonprofit organization sponsors an amateur sports event.  For purposes of this subdivision, “sports official” includes an umpire, referee, judge, scorekeeper, timekeeper, or other person who is a neutral participant in a sports event.</li>
      <li>A person who is an owner-builder who is participating in a mutual self-help housing program (as defined in Section 50087 of the Health and Safety Code) sponsored by a nonprofit corporation.</li>
    </ul>(Employees falling into any of the above categories are exempt from the requirement of workers’ compensation insurance coverage. Cal. Labor Code §3352)`,
    EXP_10:
      'You have indicated that the worker is an employee under the applicable employment test, and that the worker does not fall in to one of the exemptions from the requirement of workers’ compensation insurance coverage. California employers are required to maintain workers’ compensation insurance for all non-exempt employees from either a licensed insurance company or through the State Compensation Insurance Fund. (Cal. Labor Code, §3700 and §3600.5) The employer also has the option to self-insure for workers’ compensation benefits. (Cal. Labor Code, §3700(b)) In addition, California employers must provide unemployment insurance (Cal. Unemployment Ins. Code §976) for their employees and, withhold estimated state personal income tax from their employees’ wages. (Cal. Unemployment Ins. Code §13020) Finally, California employers must comply with all wage orders applicable to their employees. (Cal. Labor Code §1173)',
    EXP_10_5:
      'You have indicated that the worker is an employee under the applicable employment test, but that the worker falls in to one of the exemptions from the requirement of workers’ compensation insurance coverage. While an exempt employee need not be covered by workers’ compensation insurance, the employer still must provide the employee with unemployment insurance (Cal. Unemployment Ins. Code §976) and withhold estimated state personal income tax from the employee’s wages. (Cal. Unemployment Ins. Code §13020) Finally, the employer must comply with all wage orders applicable to the employee. (Cal. Labor Code §1173)',
    EXP_11: `<ul>
        <li>the author or artist and the employer expressly agree in a written agreement signed by them that the work shall be considered a work made for hire (as defined in Section 101 of Title 17 of the United States Code), and</li>
        <li>the employer obtains ownership of all the rights comprised in the copyright in the work. </li>
      </ul>`,
    EXP_11_5: `An author or artist is considered a statutory employee while engaged by contract with the employer for the creation of a specially ordered or commissioned work of authorship so long as the following two conditions are met:<br/><br/>
      <ul>
        <li>the author or artist and the employer expressly agree in a written agreement signed by them that the work shall be considered a work made for hire (as defined in Section 101 of Title 17 of the United States Code), and</li>
        <li>the employer obtains ownership of all the rights comprised in the copyright in the work.</li>
      </ul>If the above conditions are met, the party ordering or commissioning the work is the employer of the author and artist and must provide workers’ compensation insurance (see Cal. Labor Code §3351.5(c)) and unemployment insurance (see Cal. Unemployment Ins. Code §686) to the author or artist. However, the employer is not required to withhold estimated state personal income tax from the wages of the artist or author. (22 CCR §4304-1)`,
    EXP_11_6: 'You have indicated that the worker is not an employee (whether statutory or otherwise) of the company. A business without California employees is not required to provide workers’ compensation insurance, unemployment insurance or other employment benefits to its workers nor is it required to comply with California wage orders.'
  },
  nodes: [
    {
      id: 'description_1',
      kind: 'Description',
      content: {
        fields: [{ kind: 'attach', value: 'EXP_1' }],
        next: 'yesno_2'
      }
    },
    {
      id: 'yesno_2',
      kind: 'YesNo',
      content: {
        question: 'Is the worker an owner, officer or director of the business?',
        fields: [
          { label: 'Yes', next: 'single_4' },
          { label: 'No', next: 'yesno_11' }
        ]
      }
    },
    {
      id: 'single_4',
      kind: 'Single',
      content: {
        question: 'What is the legal structure of the business?',
        fields: [
          { label: 'Sole Proprietorship', next: 'final_3' },
          { label: 'Partnership or LLC', next: 'final_6' },
          { label: 'Corporation', next: 'yesno_6.5' }
        ]
      }
    },
    {
      id: 'final_3',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_2']
      }
    },
    {
      id: 'yesno_6.5',
      kind: 'YesNo',
      content: {
        question:
          'Is the worker an officer or director of the corporation',
        fields: [
          { label: 'Yes', next: 'final_6.6' },
          { label: 'No', next: 'yesno_7' }
        ]
      }
    },
    {
      id: 'final_6',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_3']
      }
    },
    {
      id: 'yesno_7',
      kind: 'YesNo',
      content: {
        question: 'Is the corporation a professional corporation?',
        fields: [
          { label: 'Yes', next: 'description_9' },
          { label: 'No', next: 'yesno_11' }
        ]
      }
    },
    {
      id: 'description_9',
      kind: 'Description',
      content: {
        fields: [{ kind: 'attach', value: 'EXP_4' }],
        next: 'yesno_11'
      },
    },
    {
      id: 'final_6.6',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_5']
      }
    },

    {
      id: 'yesno_11',
      kind: 'YesNo',
      content: {
        question: 'Is the worker one of the following?',
        description: ['EXP_6'],
        fields: [
          { label: 'Yes', next: 'yesno_11_1' },
          { label: 'No', next: 'yesno_11_2' }
        ],
      }
    },
    {
      id: 'yesno_11_1',
      kind: 'YesNo',
      content: {
        question: 'Does the company have the right to control the worker both as to the work to be done and the manner and means in which it is performed*?',
        description: ['EXP_6_1'],
        fields: [
          { label: 'Yes', next: 'description_11_5' },
          { label: 'No', next: 'description_11_6' }
        ],
      }
    },
    {
      id: 'yesno_11_2',
      kind: 'YesNo',
      content: {
        question: "Is the worker a licensed real estate salesperson working under the company's license as a real estate broker?",
        fields: [
          { label: 'Yes', next: 'final_11_3' },
          { label: 'No', next: 'yesno_11_4' }
        ],
      }
    },
    {
      id: 'final_11_3',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_6_2']
      }
    },
    {
      id: 'yesno_11_4',
      kind: 'YesNo',
      content: {
        question: "Does the worker satisfy all three of the following conditions? ",
        description: `<ol>
          <li>The worker is free from the control and direction of the company in connection with the performance of the work, both under the contract for the performance of the work and in fact;</li>
          <li>The worker performs work that is outside the usual course of the company’s business; and</li>
          <li>The worker is customarily engaged in an independently established trade, occupation, or business of the same nature as that involved in the work performed.</li>
        </ol>`,
        fields: [
          { label: 'Yes', next: 'description_11_6' },
          { label: 'No', next: 'description_11_5' }
        ],
        note: {
          title: 'The ABC Test',
          attach: ['EXP_6_3'],
        },
      }
    },
    {
      id: 'description_11_5',
      kind: 'Description',
      content: {
        fields: [{ kind: 'attach', value: 'EXP_6_4' }],
        next: 'yesno_12'
      }
    },
    {
      id: 'description_11_6',
      kind: 'Description',
      content: {
        fields: [{ kind: 'attach', value: 'EXP_6_5' }],
        next: 'yesno_15'
      }
    },
    {
      id: 'yesno_12',
      kind: 'YesNo',
      content: {
        question:
          'Does the worker fall under one of the exceptions listed below:',
        description: ['EXP_9'],
        fields: [
          { label: 'Yes', next: 'final_13' },
          { label: 'No', next: 'final_14' }
        ],
      }
    },
    {
      id: 'final_13',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_10_5']
      }
    },
    {
      id: 'final_14',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_10']
      }
    },
    {
      id: 'yesno_15',
      kind: 'YesNo',
      content: {
        question:
          "Does the company hold a contractor's license?",
        fields: [
          { label: 'Yes', next: 'yesno_20' },
          { label: 'No', next: 'yesno_17' }
        ]
      }
    },
    {
      id: 'yesno_17',
      kind: 'YesNo',
      content: {
        question:
          "Is the worker an unlicensed contractor?",
        fields: [
          { label: 'Yes', next: 'final_18' },
          { label: 'No', next: 'yesno_19' }
        ]
      }
    },
    {
      id: 'final_18',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_8']
      }
    },
    {
      id: 'yesno_19',
      kind: 'YesNo',
      content: {
        question: 'Is the worker an author or artist engaged by contract with the employer for the creation of a specially ordered or commissioned work of authorship meeting the following two conditions?',
        description: ['EXP_11'],
        fields: [
          { label: 'Yes', next: 'final_19_6' },
          { label: 'No', next: 'final_19_5' }
        ]
      }
    },
    {
      id: 'final_19_5',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_11_6']
      }
    },
    {
      id: 'final_19_6',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_11_5']
      }
    },
    {
      id: 'final_21',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_7']
      }
    },
    {
      id: 'yesno_20',
      kind: 'YesNo',
      content: {
        question: "Is the company's contractor's license qualified by a Responsible Managing Employee?",
        fields: [
          { label: 'Yes', next: 'final_21' },
          { label: 'No', next: 'yesno_22' }
        ]
      }
    },
    {
      id: 'final_21',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_7']
      }
    },
    {
      id: 'yesno_22',
      kind: 'YesNo',
      content: {
        question: 'Does the company hold an active Roofing (C-39) classification?',
        fields: [
          { label: 'Yes', next: 'final_23' },
          { label: 'No', next: 'yesno_17' }
        ]
      }
    },
    {
      id: 'final_23',
      kind: 'Final',
      content: {
        title: "Do you need to provide workers' compensation insurance and other California employee benefits?",
        attach: ['EXP_7_5']
      }
    }
  ]
}
