/* eslint-disable */
export default {
  name: 'ca_corporation_bylaws',
  description: 'Creating California Bylaws',
  start: 'description_0',
  kind: 'Form',
  step: 4,
  attach: {
    NOTE_1: `This form is designed for use by a small, privately held California corporation that is not a professional corporation. The following additional assumptions have been made about the corporation:
    <ul>
      <li>The Articles of Incorporation do not vary the statutory requirement that the majority of shareholders constitutes a quorum for shareholder meetings (Corp. code §602(a)) and that a majority of directors constitutes a quorum for directors’ meetings. (Corp. code §307(a)(7)).</li>
      <li>The Articles of Incorporation do not specify (and the Corporation does not wish that the Bylaws specify) additional persons entitled to call a special meeting of shareholders. (Corp code §600).</li>
      <li>The Corporation is not a statutory close corporation.</li>
      <li>The Corporation does not wish to establish any qualifications for directors, such as the requirement that a director be a shareholder. (See Corp. code §212(b)(4)).</li>
      <li>The Corporation does not wish to compensate the Directors for serving as directors but wishes them to receive reimbursement for their reasonable out of pocket expenses.</li>
      <li>The Corporation does not own any insurance company from which it acquires liability insurance to protect its directors or agents. (Corp code §317).</li>
      <li>The Corporation does not wish to allow the Board of Directors to amend the Bylaws. (Corp. code §211).</li>
    </ul>`,
    NOTE_2: `California corporations are required to have at least three directors, except in the
    following cases:
    <ul>
    <li>Before any shares are issued, the corporation may have one or two directors.</li>
    <li>So long as the corporation has only one shareholder, the corporation may have
    one or two directors.</li>
    <li>So long as the corporation has only two shareholders, the corporation may have
    two directors.</li>
    </ul>(See Corp. code §212).`,
  },
  nodes: [
    {
      id: 'description_0',
      kind: 'Description',
      content: {
        question: 'This form is designed for use by a small, privately held California corporation that is not a professional corporation.',
        fields: [{
          value: 'Click <strong>ⓘ</strong> for a more detailed explanation of this form and its limitations.'
        }],
        note: {
          title: 'About this Form',
          attach: ['NOTE_1'],
        },
        next: 'input_1',
      },
    },
    {
      id: 'input_1',
      kind: 'Input',
      content: {
        question: 'What is the name of the corporation?',
        fields: [{ kind: 'text', store: 'name_of_corporation' }],
        next: 'input_2',
      },
    },
    {
      id: 'input_2',
      kind: 'Input',
      content: {
        question:
          'The corporation must hold an annual shareholders meeting. On what day and month (each year) shall this meeting be held?<br/><small>(Note that the board of directors may change this date by notice to the shareholders.)</small>',
        fields: [{ kind: 'text', store: 'annual_meeting_date' }],
        next: 'input_3',
      },
    },
    {
      id: 'input_3',
      kind: 'Input',
      content: {
        question:
          "How many directors will serve on the corporation's board of directors?",
        description:
          '(Note that subject to certain exceptions, a corporation must have at least three directors. Click <strong>ⓘ</strong> to view the exceptions.)',
        fields: [{ kind: 'text', store: 'number_of_directors' }],
        note: {
          title: 'Number of Directors',
          attach: ['NOTE_2'],
        },
        next: 'final',
      },
    },
    {
      id: 'final',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'ca_corporation_bylaws',
      },
    },
  ],
}
