/* eslint-disable */
export default {
  name: 'website_terms_of_use',
  description: 'Creating Website Terms of Use',
  start: 'single_1',
  kind: 'Form',
  step: 8,
  nodes: [
    {
      id: 'single_1',
      kind: 'Single',
      content: {
        question: 'Can visitors purchase products through your website?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_2' },
          { kind: 'choice', label: 'No', next: 'input_2_2' },
        ],
      },
    },
    {
      id: 'input_2_2',
      kind: 'Input',
      content: {
        question: 'What is the name of your company?',
        fields: [{ kind: 'text', store: 'company_name' }],
        next: 'input_2_3',
      },
    },
    {
      id: 'input_2_3',
      kind: 'Input',
      content: {
        question:
          'In what state is the principal office of your company located?',
        fields: [{ kind: 'text', store: 'company_location' }],
        next: 'final',
      },
    },
    {
      id: 'final',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'website_terms_of_use',
      },
    },
    {
      id: 'input_2',
      kind: 'Input',
      content: {
        question: 'What is the name of your company?',
        fields: [{ kind: 'text', store: 'company_name' }],
        next: 'single_4',
      },
    },
    {
      id: 'single_4',
      kind: 'Single',
      content: {
        question:
          'Do you notify customers when delivery of their order has been delayed?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_5' },
          { kind: 'choice', label: 'No', next: 'yesno_8' },
        ],
        store: 'notify_customers'
      },
    },
    {
      id: 'input_5',
      kind: 'Input',
      content: {
        question:
          'What is the number of days of delay that trigger such a notice?',
        fields: [
          {
            kind: 'select',
            datasource: new Array(30).fill(1).map((e, index) => index + 1),
            store: 'number_of_days',
          },
          {
            kind: 'select',
            datasource: ['business', 'calendar'],
            store: 'day_type',
          },
        ],
        next: 'yesno_8',
      },
    },
    {
      id: 'yesno_8',
      kind: 'YesNo',
      content: {
        question: 'Do you allow returns when permitted by the manufacturer?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_9' },
          { kind: 'choice', label: 'No', next: 'input_11' },
        ],
        store: 'allow_returns'
      },
    },
    {
      id: 'input_9',
      kind: 'Input',
      content: {
        question: 'How should a customer contact you to obtain authorization for a return?',
        fields: [
          { kind: 'text', placeholder: 'Call the following telephone number', store: 'phone_of_returns_department', optional: true },
          { kind: 'text', placeholder: 'Send an email to the following email address', store: 'email_of_returns_department', optional: true },
        ],
        required: true,
        next: 'input_11'
      },
    },
    {
      id: 'input_11',
      kind: 'Input',
      content: {
        question: 'In what state is the principal office of your company located?',
        fields: [
          { kind: 'select', datasource: 'state', store_value: 'name' },
        ],
        store: 'company_location',
        next: 'final_2',
      }
    },
    {
      id: 'final_2',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'website_terms_of_use_2',
      },
    },
  ],
}
