/* eslint-disable */
export default {
  name: 'paycheck_protection',
  description: 'Paycheck Protection Loans',
  start: 'action_1',
  kind: 'Form',
  attach: {
    NOTE_1:
      `The term “payroll costs” is defined in Section 1102(a)(2) of the CARES Act. The definition varies based on your business structure as follows:
      <div class="text-center" style="text-decoration: underline; margin: 12px auto;"><strong>Corporations, LLCs and other Business Entities with Employees</strong></div>
      <p>All of the following:</p>
      <ul>
        <li>Salaries, wages and other employee compensation; payment of cash tip or equivalent; payment for vacation, parental, family, medical, or sick leave; </li>
        <li>allowance for dismissal or separation (severance pay);</li>
        <li>medical insurance premiums for employees and any other payment required for the provisions of group health care benefits;</li>
        <li>payment of any retirement benefit, such as the employer contribution to employees’ 401(k) plans; and</li>
        <li>payment of State or local tax assessed on the compensation of employees, which will typically consist solely in employer payments for state unemployment insurance. Federal payroll taxes are excluded.</li>
      </ul>
      <p>Note the following are NOT considered “payroll costs”:</p>
      <ul>
        <li>Employee compensation is capped at $100,000. Thus, for any employee whose annual compensation exceeds $100,000, the amount of compensation over $100,000 is excluded from the definition of “payroll costs”;</li>
        <li>any compensation of an employee whose principal place of residence is outside of the United States;</li>
        <li>compensation of any independent contractor (Per Part III, Section 2(h) of the newly issued SBA regulations on the Paycheck Protection Program, “Independent contractors have the ability to apply for a PPP loan on their own so they do not count for purposes of a borrower’s PPP loan calculation.”);  and</li>
        <li>qualified sick leave wages or qualified family leave wages for which a credit is allowed under the Families First Coronavirus Response Act.</li>
      </ul>
      <div class="text-center" style="text-decoration: underline; margin: 12px auto;"><strong>Sole Proprietorships with Employees</strong></div>
      <p>All of the following:</p>
      <ul>
        <li>The sole proprietor’s net earnings from self-employment;</li>
        <li>salaries, wages and other employee compensation; payment of cash tip or equivalent; payment for vacation, parental, family, medical, or sick leave; </li>
        <li>allowance for dismissal or separation (severance pay);</li>
        <li>medical insurance premiums for employees and any other payment required for the provisions of group health care benefits;</li>
        <li>payment of any retirement benefit, such as the employer contribution to employees’ 401(k) plans; and</li>
        <li>payment of State or local tax assessed on the compensation of employees, which will typically consist solely in employer payments for state unemployment insurance. Federal payroll taxes are excluded.</li>
      </ul>
      <p>Note the following are NOT considered “payroll costs”:</p>
      <ul>
        <li>Employee compensation is capped at $100,000. Thus, for any employee whose annual compensation exceeds $100,000, the amount of compensation over $100,000 is excluded from the definition of “payroll costs”;</li>
        <li>any compensation of an employee whose principal place of residence is outside of the United States;</li>
        <li>compensation of any independent contractor (Per Part III, Section 2(h) of the newly issued SBA regulations on the Paycheck Protection Program, “Independent contractors have the ability to apply for a PPP loan on their own so they do not count for purposes of a borrower’s PPP loan calculation.”);  and</li>
        <li>qualified sick leave wages or qualified family leave wages for which a credit is allowed under the Families First Coronavirus Response Act.      </li>
      </ul>
      <div class="text-center" style="text-decoration: underline; margin: 12px auto;"><strong>Independent Contractors without Employees</strong></div>
      <ul>
        <li>The independent contractor’s net earnings from self-employment.</li>
      </ul>`,
    NOTE_2: `<p>
      <span>Balance of SBA Economic Injury Disaster Loans: <strong>\${DL}</strong></span><br/>
      <span>Average Monthly Payroll Costs for the applicable prior period: <strong>\${AVERAGE_MONTHLY_PAYROLL_COST}</strong></span>
    </p>
    <p>Under Section 1102(a)(2) of the CARES Act, the maximum loan amount is equal to the balance of your SBA economic injury disaster loans plus 250% of your Average Monthly Payroll Costs for the applicable period, but in no event greater than $10 million. Based on the amounts you entered, the maximum loan amount is <strong>\${MAXIMUM_LOAN_AMOUNT}</strong>.</p>
    `,
    NOTE_3: `<p>
      <span>Interest on Covered Mortgage Obligations: <strong>\${MORTGAGE_INTEREST}</strong></span><br/>
      <span>Rent on Covered Rental Obligations: <strong>\${RENT}</strong></span><br/>
      <span>Covered Utility Payments: <strong>\${UTILITIES}</strong></span><br/>
      <span>Payroll Costs: <strong>\${PAYROLL_COSTS}</strong></span>
    </p>
    <p>Thus your total expenditure on allowable overhead items is <strong>\${OVERHEAD}</strong>. An amendment to the CARES Act provides that in order to receive loan forgiveness, the “recipient shall use at least 60% of the covered loan amount for payroll costs.” (Section 1106(d)(8))</p>`,
    NOTE_4: `<p>According to the SBA, a “full-time employee” is an employee who is employed on average at least 30 hours per week and a “full-time equivalent employee” is a combination of employees, each of whom individually is not a full-time employee because they are not employed on average at least 30 hours per week, but who, in combination, are counted as the equivalent of a full-time employee. For example, two employees, each of whom works 15 hours per week, are the equivalent of one full-time employee.</p>
      <p>For purposes of this calculation, the following rules apply:</p>
      <p>First, “the average number of full-time equivalent employees shall be determined by calculating the average number of full-time equivalent employees for each pay period falling within a month.” (CARES Act, §1106(d)(2)(B))</p>
      <p>Second, you must exclude any employee who “received, during any single pay period during 2019, wages or salary at an annualized rate of pay in an amount more than $100,000”. (CARES Act, §1106(d)(3)(B))</p>
      <p>Third, “the amount of loan forgiveness … shall be reduced by the amount of any reduction in total salary or wages of any [eligible] employee … during the covered period that is in excess of 25 percent of the total salary or wages of the employee during the most recent full quarter during which the employee was employed before the” date on which you receive the Paycheck Protection Loan.  (CARES Act, §1106(d)(3)(A)) However, if this reduction in pay is eliminated by December 31, 2020, then it is disregarded. (CARES Act, §1106(d)(5))</p>`,
    NOTE_5_1: `You have indicated that your company will not experience a reduction in the number of full-time equivalent employees. Accordingly, your final forgiveness amount is the lesser of: (a) your total expenditures on allowable overhead, (b) the amount of your Paycheck Protection Loan, and (c) your payroll costs divided by 0.60. Therefore, your final forgiveness amount is equal to <strong>\${FORGIVENESS_AMOUNT}</strong>.`,
    NOTE_5_2: `You have indicated that your company will experience a reduction in the number of full-time equivalent employees. Under Section 1106(d) of the CARES Act, if there is a reduction in employment, the amount of loan forgiveness is reduced by the ratio of the number of expected employees over the number of historic employees. You have reported that ratio to be <strong>\${RATIO}</strong>. Accordingly, your final forgiveness amount is the lesser of: (a) your total expenditures on allowable overhead multiplied by such ratio (b) the amount of your Paycheck Protection Loan, and (c) your payroll costs divided by 0.60. Therefore, your final forgiveness amount is equal to <strong>\${FORGIVENESS_AMOUNT}</strong>. Keep in mind, however, that if your company had a reduction in employees but hires additional employees or rehires terminated employees not later than December 31, 2020, any such reduction (that is covered by such hired or rehired employees) shall be disregarded and shall not reduce the loan forgiveness amount. (CARES Act, §1106(d)(5)) In addition, any reduction in employment is disregarded if your company can document an inability to rehire former employees or find similarly qualified replacement employees by December 31, 2020. (CARES Act, §1106(d)(7)) Finally, any reduction in employment will also be disregarded if your company can document an inability to return to its previous level of business activity (before February 15, 2020) due to compliance with stay at home orders or other COVID-19 regulations. (Id.)`,
    NOTE_6: `A business is seasonal if it employs its workers for “recurring periods of work of less than 12 months each year.” Such workers are “permanent employees who are placed in nonduty/nonpay status and recalled to duty in accordance with preestablished conditions of employment.” (See 5 CFR §340.401)`
  },
  step: 18,
  nodes: [
    {
      id: 'action_1',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'DL',
        value: 0,
        next: 'yesno_2'
      }
    },
    {
      id: 'yesno_2',
      kind: 'YesNo',
      content: {
        question: 'Did your company obtain an SBA economic injury disaster loan on or after January 31, 2020?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_3' },
          { kind: 'choice', label: 'No', next: 'yesno_5' },
        ]
      },
    },
    {
      id: 'input_3',
      kind: 'Input',
      content: {
        question: "What is the current outstanding balance of your company's economic injury disaster loan?",
        fields: [{ kind: 'number', store: 'DL' }],
        next: 'yesno_5',
      },
    },
    {
      id: 'yesno_5',
      kind: 'YesNo',
      content: {
        question: 'Was your company in business during the period beginning on February 15, 2019 and ending on June 30, 2019?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'yesno_7' },
          { kind: 'choice', label: 'No', next: 'input_6' },
        ]
      },
    },
    {
      id: 'input_6',
      kind: 'Input',
      content: {
        question: "What was your company's average monthly payments for payroll costs during January and February 2020?",
        fields: [{ kind: 'number', store: 'AVERAGE_MONTHLY_PAYROLL_COST' }],
        next: 'action_11_1',
        note: {
          title: 'PAYROLL COSTS',
          attach: ['NOTE_1']
        }
      },
    },
    {
      id: 'yesno_7',
      kind: 'YesNo',
      content: {
        question: "Is your company's business seasonal?",
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_8' },
          { kind: 'choice', label: 'No', next: 'input_9' },
        ],
        note: {
          title: 'SEASONAL',
          attach: ['NOTE_6']
        }
      },
    },
    {
      id: 'input_8',
      kind: 'Input',
      content: {
        question: "What was your company's average monthly payments for payroll costs during the period beginning February 15, 2019 (or at your election, March 1, 2019) and ending June 30, 2019?",
        fields: [{ kind: 'number', store: 'AVERAGE_MONTHLY_PAYROLL_COST' }],
        next: 'action_11_1',
        note: {
          title: 'PAYROLL COSTS',
          attach: ['NOTE_1']
        }
      },
    },
    {
      id: 'input_9',
      kind: 'Input',
      content: {
        question: "What was your company's average monthly payments for payroll costs during the one year period ending on the date on which you receive your Paycheck Protection Loan?",
        fields: [{ kind: 'number', store: 'AVERAGE_MONTHLY_PAYROLL_COST' }],
        next: 'action_11_1',
        note: {
          title: 'PAYROLL COSTS',
          attach: ['NOTE_1']
        }
      },
    },
    
    {
      id: 'action_11_1',
      kind: 'Action',
      content: {
        kind: 'SET_VALUES',
        store: 'TENTATIVE_MAXIMUM',
        values: [['AVERAGE_MONTHLY_PAYROLL_COST', 2.5], ['DL']],
        next: 'action_11_2'
      }
    },
    {
      id: 'action_11_2',
      kind: 'Action',
      content: {
        kind: 'SWITCH_VALUE',
        store: 'TENTATIVE_MAXIMUM',
        value: [10000000],
        next: ['action_11_3', 'action_11_4']
      }
    },
    {
      id: 'action_11_3',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'MAXIMUM_LOAN_AMOUNT',
        value: ['TENTATIVE_MAXIMUM'],
        next: 'description_11'
      }
    },
    {
      id: 'action_11_4',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'MAXIMUM_LOAN_AMOUNT',
        value: 10000000,
        next: 'description_11'
      }
    },
    {
      id: 'description_11',
      kind: 'Description',
      content: {
        question: 'You have entered the following amounts:',
        fields: [{ kind: 'attach', value: 'NOTE_2' }],
        next: 'yesno_12'
      }
    },
    {
      id: 'yesno_12',
      kind: 'YesNo',
      content: {
        question: "Do you wish to estimate the amount of your Paycheck Protection Loan that will be forgiven?",
        fields: [
          { kind: 'choice', label: 'Yes', next: 'action_13_1' },
          { kind: 'choice', label: 'No', next: 'final_1' },
        ]
      },
    },
    {
      id: 'final_1',
      kind: 'Final',
      content: {
        title: 'Paycheck Protection Loans',
        message: 'Thank you! Click on <strong>Finish</strong>.'
      },
    },
    {
      id: 'action_13_1',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'MORTGAGE_INTEREST',
        value: 0,
        next: 'action_13_2'
      }
    },
    {
      id: 'action_13_2',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'RENT',
        value: 0,
        next: 'input_14'
      }
    },
    {
      id: 'input_14',
      kind: 'Input',
      content: {
        question: "What are your company's estimated payroll costs for the Covered Period?",
        description: 'The "Covered Period" begins when your company receives its Paycheck Protection Loan and ends 24 weeks later or on December 31, 2020, whichever occurs first. However, if your company received its loan before June 5, 2020, it may elect for the Covered Period to end 8 weeks after receipt of the loan proceeds.',
        fields: [{ kind: 'number', store: 'PAYROLL_COSTS' }],
        next: 'yesno_16',
        note: {
          title: 'PAYROLL COSTS',
          attach: ['NOTE_1']
        }
      },
    },
    {
      id: 'yesno_16',
      kind: 'YesNo',
      content: {
        question: "Did your company obtain a loan in the ordinary course of business secured by a mortgage on real or personal property before February 15, 2020, which remains outstanding? If so, any such loan is called a Covered Mortgage Loan.",
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_17' },
          { kind: 'choice', label: 'No', next: 'yesno_19' },
        ]
      },
    },
    {
      id: 'input_17',
      kind: 'Input',
      content: {
        question: "What are the total estimated interest payments due on your company's Covered Mortgage Loans for the Covered Period?",
        fields: [{ kind: 'number', store: 'MORTGAGE_INTEREST' }],
        next: 'yesno_19',
      },
    },
    {
      id: 'yesno_19',
      kind: 'YesNo',
      content: {
        question: "Was your company the tenant under a leasing agreement in force before February 15, 2020? If so, any such lease is called a Covered Rent Obligation.",
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_20' },
          { kind: 'choice', label: 'No', next: 'input_22' },
        ]
      },
    },
    {
      id: 'input_20',
      kind: 'Input',
      content: {
        question: "What are the total estimated rent payments due on your company's Covered Rent Obligations for the Covered Period?",
        fields: [{ kind: 'number', store: 'RENT' }],
        next: 'input_22',
      },
    },

    {
      id: 'input_22',
      kind: 'Input',
      content: {
        question: "What are your company's estimated payments for utilities for the Covered Period? Note that eligible utilities are electricity, gas, water, transportation, telephone or internet access for which service began before February 15, 2020.",
        fields: [{ kind: 'number', store: 'UTILITIES' }],
        next: 'input_24'
      },
    },

    {
      id: 'input_24',
      kind: 'Input',
      content: {
        question: "What is the amount of your Paycheck Protection Loan?",
        fields: [{ kind: 'number', store: 'LOAN_AMOUNT' }],
        next: 'action_26'
      },
    },

    {
      id: 'action_26',
      kind: 'Action',
      content: {
        kind: 'SET_VALUES',
        store: 'OVERHEAD',
        values: [['UTILITIES'], ['RENT'], ['MORTGAGE_INTEREST'], ['PAYROLL_COSTS']],
        next: 'description_26'
      }
    },

    {
      id: 'description_26',
      kind: 'Description',
      content: {
        question: 'You have entered the following values:',
        fields: [{ kind: 'attach', value: 'NOTE_3' }],
        next: 'input_27'
      }
    },
    {
      id: 'input_27',
      kind: 'Input',
      content: {
        question: "What is the average number of full-time equivalent employees per month that your company expects to employ over the Covered Period after receiving its Paycheck Protection Loan?",
        description: 'The "Covered Period" begins when your company receives its Paycheck Protection Loan and ends 24 weeks later or on December 31, 2020, whichever occurs first. However, if your company received its loan before June 5, 2020, it may elect for the Covered Period to end 8 weeks after receipt of the loan proceeds.',
        fields: [{ kind: 'number', store: 'EXPECTED_EMPLOYMENT' }],
        next: 'yesno_29',
        note: {
          title: 'FULL-TIME EMPLOYEE',
          attach: ['NOTE_4']
        }
      },
    },
    {
      id: 'yesno_29',
      kind: 'YesNo',
      content: {
        question: "Are you a seasonal employer?",
        fields: [
          { kind: 'choice', label: 'Yes', next: 'input_30' },
          { kind: 'choice', label: 'No', next: 'input_31' },
        ]
      },
    },
    {
      id: 'input_30',
      kind: 'Input',
      content: {
        question: "What is the average number of full-time equivalent employees per month that your company employed during the period from February 15, 2019 through June 30, 2019?",
        fields: [{ kind: 'number', store: 'HISTORICAL_EMPLOYMENT' }],
        next: 'action_33',
      },
    },
    {
      id: 'input_31',
      kind: 'Input',
      content: {
        question: "Select the time period during which your company had the lowest number (on average) of full-time equivalent employees per month: February 15, 2019 through June 30, 2019 or January 1, 2020 through February 29, 2020. Then provide the monthly average number of full-time equivalent employees during such time period.",
        fields: [{ kind: 'number', store: 'HISTORICAL_EMPLOYMENT' }],
        next: 'action_33',
      },
    },
    {
      id: 'action_33',
      kind: 'Action',
      content: {
        kind: 'SET_VALUES',
        store: 'ADJUSTED_PAYROLL_COSTS',
        values: [['PAYROLL_COSTS', 0.60, '/']],
        next: 'action_34'
      }
    },
    {
      id: 'action_34',
      kind: 'Action',
      content: {
        kind: 'SWITCH_VALUE',
        store: 'EXPECTED_EMPLOYMENT',
        value: [['HISTORICAL_EMPLOYMENT']],
        next: ['action_34_2', 'action_34_1']
      }
    },
    {
      id: 'action_34_1',
      kind: 'Action',
      content: {
        kind: 'SET_VALUE',
        store: 'ADJUSTED_OVERHEAD',
        value: ['OVERHEAD'],
        next: 'action_34_1_2'
      }
    },
    {
      id: 'action_34_1_2',
      kind: 'Action',
      content: {
        kind: 'MIN_VALUE',
        store: 'FORGIVENESS_AMOUNT',
        values: [['ADJUSTED_OVERHEAD'], ['LOAN_AMOUNT'], ['ADJUSTED_PAYROLL_COSTS']],
        next: 'final_2_1'
      }
    },
    {
      id: 'action_34_2',
      kind: 'Action',
      content: {
        kind: 'SET_VALUES',
        store: 'RATIO',
        values: [['EXPECTED_EMPLOYMENT', ['HISTORICAL_EMPLOYMENT'], '/']],
        next: 'action_34_2_2'
      }
    },
    {
      id: 'action_34_2_2',
      kind: 'Action',
      content: {
        kind: 'SET_VALUES',
        store: 'ADJUSTED_OVERHEAD',
        values: [['OVERHEAD', ['RATIO'], '*']],
        next: 'action_34_2_3'
      }
    },
    {
      id: 'action_34_2_3',
      kind: 'Action',
      content: {
        kind: 'MIN_VALUE',
        store: 'FORGIVENESS_AMOUNT',
        values: [['ADJUSTED_OVERHEAD'], ['LOAN_AMOUNT'], ['ADJUSTED_PAYROLL_COSTS']],
        next: 'final_2_2'
      }
    },
    {
      id: 'final_2_1',
      kind: 'Final',
      content: {
        title: 'Paycheck Protection Loans',
        attach: ['NOTE_5_1']
      }
    },
    {
      id: 'final_2_2',
      kind: 'Final',
      content: {
        title: 'Paycheck Protection Loans',
        attach: ['NOTE_5_2']
      }
    },
  ],
}
