/* eslint-disable */
export default {
  name: 'securities',
  description: 'Securities',
  start: 'yesno_2',
  kind: 'Form',
  attach: {
    NOTE_2: `“General solicitation or advertising” includes advertisements published in newspapers and magazines, public websites, communications broadcast over television and radio, and seminars where attendees have been invited by general solicitation or general advertising. (See 17 CFR §230.502(c)) In addition, the use of an unrestricted, and therefore publicly available, website constitutes general solicitation.  The solicitation must be an “offer” of securities, but solicitations that condition the market for an offering of securities may be considered to be offers.`,
    NOTE_3: `The new crowdfunding exemption is the only exemption from registration that allows the use of advertising to promote sales to non-accredited investors. However, the crowd funding regulations require detailed disclosures to investors, including the company’s business plan, income tax returns and financial statements. (15 U.S.C. §77d-1(b)(1)) For more information about the crowd funding exemption, click <a class="lg-show-description" href="#link_3_1">here</a>.<description id="link_3_1">{{LINK_3_1}}<a href="#">Close</a></description> A second exemption – Rule 1001 - permits a general announcement of the offering providing a brief description of the company’s business, the price of the stock and other limited information, but no general advertising. (17 CFR §230.1001 and Cal. Corp. Code §25102(n)) For an explanation of why the other exemptions and safe harbors do not apply, click <a class="lg-show-description" href="#link_3_2">here</a>.<description id="link_3_2">{{LINK_3_2}}<a href="#">Close</a></description> Click continue to explore whether the offering qualifies under the crowd funding exemption or under Rule 1001.`,
    NOTE_4: `Rule 506(b) authorizes the sale of securities to up to 35 non-accredited investors so long as:
    <ul>
      <li>The company is not subject to the “bad actor” ban. (17 CFR §230.506(d)).</li>
      <li>The offering is not promoted by general solicitation or advertising. (17 CFR §230.506(b)(1); 17 CFR §230.502(c)).</li>
      <li>Each non-accredited investor receives certain required written disclosures “a reasonable time prior to sale.” (17 CFR §230.506(b)(1); 17 CFR §230.502(b)).</li>
      <li>The securities are subject to restrictions on resale. (17 CFR §230.506(b)(1); 17 CFR §230.502(d)).</li>
      <li>The company files with the SEC a notice of sale (Form D) within 15 days after the first sale of the securities in the offering. (17 CFR §230.503(a)(1))</li>
    </ul>Since Rule 506(b) preempts state law, offerings in compliance with Rule 506(b) are automatically in compliance with state law, except that each state has the right to require that a copy of the notice of sales (Form D) be filed with the state. (15 USC §77r(b)(4)(F)) The State of California has imposed this requirement.
    According to the SEC, “Even if a company takes advantage of an exemption from registration, a company should take care to provide sufficient information to investors to avoid violating the antifraud provisions of the securities laws. This means that any information a company provides to investors must be free from false or misleading statements. Similarly, a company should not exclude any information if the omission makes what is provided to investors false or misleading.” 
    In order to document the fact that the company has not made false promises to the investors, attorneys generally recommend that the company and the investor enter into a subscription agreement under which both parties agree on what promises have or have not been made to (or by) the investor. Thus, the investor will often make representations concerning his or her suitability to invest in the offering. In a Rule 506(b) offering, the company will need to know whether the investor is an accredited investor as the company is required to make certain written disclosures to non-accredited investors. In addition, the subscription agreement is a contractual commitment by the parties to enter into a sale of stock and sets forth the terms of the sale. Finally, the subscription agreement notifies that investor what restrictions, if any, apply to the stock being purchased. Thus, in a Rule 506(b) offering, the subscription agreement will warn the investor that the stock will be subject to restrictions on resale. If you wish to order a subscription agreement, click on the button below.
    `,
    NOTE_5: `The Investment Company Act of 1940 defines “investment company” as any issuer which:
    “is or holds itself  out as being engaged primarily, or propose to engage primarily, in the business of investing, reinvesting, or trading in securities…; is engaged or proposes to engage in the business of issuing face-amount certificates of the installment type, or has been engaged in such business and has any such certificate outstanding…; or is engaged or proposes to engage in the business of investing, reinvesting, owning, holding, or trading in securities, and owns or proposes to acquire investment securities having a value exceeding 40 per centum of the value of such issuer’s total assets (exclusive of Government securities and cash items) on an unconsolidated basis.” 15 U.S.C. §80a-3.
    Additionally, section 3(c)(5) of the ICA excludes from the definition of “investment company” 
    “[a]ny person who is not engaged in the business of issuing redeemable securities, face-amount certificates of the installment type of periodic payment plan certificates and who is primarily engaged in ... purchasing or otherwise acquiring mortgages and other liens on and interests in real estate.” 15 U.S.C. §80a-3(c)(5).
    `,
    NOTE_6: 'You have indicated that the company is an investment company and that either the company does not plan to provide detailed disclosure documents to non-accredited investors or plans to sell stock to more than 35 non-accredited investors. If the company, as an issuer of securities, is an investment company, it is not eligible for the exemption/safe harbor under Rule 504 (17 CFR §230.504(a)(2)) or under Rule 1001 (17 CFR §230.1001; Cal. Corp. Code §25102(n)(1)) or under the crowdfunding exemption (15 USC §77d-1(f)) or under the intrastate exemption of Rule 147A (17 CFR §230.147A(a)). Additionally, if the company does not plan to provide disclosure documents to non-accredited investors or plans to sell stock to more than 35 non-accredited investors, the Rule 506(b) safe harbor is not available. (17 CFR §230.502(b) and 17 CFR §230.506(b)(2)(i))',
    NOTE_7: `An issuer is considered to be “doing business” in California if it satisfies at least one of the following requirements:
    <ul>
      <li>the issuer derived at least 80% of its consolidated gross revenues from the operation of a business or of real property located in-state or from the rendering of services in-state;</li>
      <li>the issuer had at least 80% of its consolidated assets located in-state;</li>
      <li>the issuer intends to use and uses at least 80% of the net proceeds from the offering towards the operation of a business or of real property in-state, the purchase of real property located in-state, or the rendering of services in-state; or</li>
      <li>a majority of the issuer’s employees are based in California. ((17 CFR 230.147A(c)(2))</li>
    </ul>`,
    NOTE_8: 'An issuer is “deemed to have its principal place of business in a state or territory in which the officers, partners or managers of the issuer primarily direct, control and coordinate the activities of the issuer.” (17 CFR 230.147A(c)(1))',
    NOTE_9: `An offering that is sold only to California residents is exempt from federal registration requirements under the intrastate exemption of Rule 147A if the issuer satisfies all of the following requirements:
    <ul>
      <li>The issuer is not an investment company registered or required to be registered under the Investment Company Act of 1940.</li>
      <li>The issuer has its principal place of business in California.</li>
      <li>The issuer does business in California.</li>
      <li>The securities may not be resold to non-residents for six months after the sale by the issuer, and the issuer must place a legend on the securities warning against any such prohibited interstate sales.</li>
      <li>Each purchaser must provide a written representation that he or she is a California resident. (17 CFR §230.147A)</li>
    </ul>However, even if the offering is exempt from federal registration requirements, it still must comply with California securities laws. To determine compliance with California securities laws, click <strong>Continue</strong>.
    `,
    NOTE_10: `The “bad actor ban” disqualifies certain offerings from relying on certain exemptions from registration. The ban applies if the issuer or other <strong>covered person</strong> has experienced a <strong>disqualifying event</strong>, such as being convicted of, or sanctioned for, securities fraud or other violations of specified laws that occurred or was issued on or after September 23, 2013. (See 17 CFR §230.506(d))
    <br />
    <strong>Covered Persons</strong>
    Understanding the categories of persons that are covered is important because issuers are required to conduct a factual inquiry to determine whether any covered person has had a disqualifying event, and the existence of such an event will generally disqualify the offering from reliance on the Rule 504 or Rule 506 safe harbors.
    “Covered persons” include:
    <ul>
      <li>the issuer, including its predecessors and affiliated issuers;</li>
      <li>directors, officers, general partners or managing members of the issuer;</li>
      <li>beneficial owners of 20% or more of the issuer’s outstanding voting equity securities, calculated on the basis of voting power;</li>
      <li>promoters connected with the issuer in any capacity at time of sale; and</li>
      <li>persons compensated for soliciting investors, including the general partners, directors, officers or managing members of any such solicitor. (See 17 CFR §230.506(d)(1))</li>
    </ul>
    <strong>Disqualifying Events</strong>
    Disqualifying events mean:
    <ul>
      <li>Any criminal conviction of a covered person in connection with securities transactions;</li>
      <li>Any court injunction, order or judgement that currently restrains a covered person from engaging in certain conduct in connection with securities transactions;</li>
      <li>Any final order of a state agency that regulates securities or banks that currently bars a covered person from engaging in the securities or banking business;</li>
      <li>Any SEC order that currently suspends or revokes a covered person’s registration as a broker, dealer or investment adviser or places limitations on such person’s activities or business associations;</li>
      <li>Any SEC order that currently commands a covered person to cease and desist from committing certain securities violations;  </li>
      <li>The suspension or expulsion of a covered person from membership in a self-regulatory organization (SRO), such as FINRA, or being barred from association with an SRO member;</li>
      <li>Any SEC order (or investigation preliminary to an order) suspending or stopping the Regulation A exemption of filed by a covered person; and</li>
      <li>Any U.S. Postal Service false representation order that currently applies to a covered person. (Id.)
    (As used above, the term “securities transaction” refers to the purchase or sale of any security, the making of any false filing with the SEC or any transaction in connection with the securities business.)</li>
    </ul>
    `,
    NOTE_11: 'Neither the Rule 504 safe harbor nor the Rule 506(b) safe harbor is available to an issuer that is subject to the bad actor ban. (See 17 CFR 230.504(b)(3) and 17 CFR 230.506(d)). In addition, the crowd funding exemption is not available to an issuer that is subject to the bad actor ban when the disqualifying event occurred on or after May 16, 2016. ((17 CFR 227.503(b)(1))',
    NOTE_12: 'A “blank check company” is a development stage company that either has no specific business plan or purpose or has indicated that its business plan is to engage in a merger or acquisition with an unidentified company or companies.',
    NOTE_13: `You have indicated that the company is not an investment company or blank check company and that it is not subject to the “bad actor” ban. You have also indicated that the company does not expect to use general solicitation or advertising to market the securities. The safe harbor known as “Rule 504” is available to companies satisfying those requirements when the offering does not exceed $5 million with a 12-month period. Specifically, Rule 504 authorizes the sale of securities to non-accredited investors so long as:
    <ul>
      <li>The company is not an investment company or a blank check company. (17 CFR 230.504(a)).</li>
      <li>The company is not subject to the “bad actor” ban. (17 CFR 230.504(b)(3)).</li>
      <li>The offering is not promoted by general solicitation or advertising. (17 CFR 230.504(b)(1); 17 CFR 230.502(c)).</li>
      <li>The offering does not exceed $5 million within a 12-month period. (17 CFR 230.504(b)(2)).  </li>
      <li>The securities are subject to restrictions on resale. (17 CFR 230.504(b)(1); 17 CFR 230.502(d)).</li>
      <li>The company files with the SEC a notice of sale (Form D) within 15 days after the first sale of the securities in the offering. (17 CFR §230.503(a)(1))</li>
    </ul>
    Since Rule 504 does not preempt state law, the offering must also comply with applicable state law. If the company’s offering complies with Rule 504 and you wish to explore whether the offering complies with California law, click <strong>Continue</strong>.
    `,
    NOTE_14: 'Offerings by blank check companies (referred to as “blind pool” companies in California) are not eligible for the Rule 504 or Rule 1001 exemption/safe harbor. (17 CFR 230.504(a)(3); 17 CFR 230.1001(a) and Cal. Corp. Code 25102(n)(1)) You have indicated that the company does not plan to make disclosures to non-accredited investors. Rule 506(b) and the crowd funding safe harbors are not available to companies that do not make certain disclosures to non-accredited investors.',
    NOTE_15: `Under the Rule 1001 exemption, certain offerings which satisfy the requirements of Section 25102(n) of the California Corporations Code are exempt from federal and California registration requirements. (17 CFR 230.1001) The combined requirements of Rule 1001 and Section 25102(n) are as follows::<br/>
    <ul>
      <li><u>Offering Price Limitation</u>. The sum of all cash and other consideration received for the securities may not exceed $5 million. (17 CFR §230.1001(b))</li>
      <li><u>Issuer Requirements</u>. The issuer must be an entity formed in California or a foreign corporation if a majority of its voting securities are held by California residents and the average of its percentages of property, payroll, and sales in California, as determined for state tax purposes, is greater than 50%. The issuer may not be a “blind pool” issuer (referred to as “blank check” company under federal law) or an investment company subject to the Investment Company Act of 1940. (§25102(n)(1))</li>
      <li><u>Investor Requirements</u>. Investors must be “qualified purchasers” or other persons the issuer reasonably believes, after reasonable inquiry, to be “qualified purchasers”. A “qualified purchaser” includes an individual who, with his or her spouse, has a net worth of at least $250,000 and income of over $100,000 or who has a net worth of at least $500,000 with no income requirement, provided that the issuer has only one class of voting common stock. (See Section 25102(n)(2) for a complete definition of “qualified purchasers”.) Each investor must represent that the investor is purchasing the security for the investor's own account and not with a view to or for sale in connection with a distribution of the security. (§25102(n)(3))</li>
      <li><u>Disclosure Requirements</u>. Individual investors (and entities formed by individuals for the purpose of investing) must receive a disclosure statement unless they are sophisticated investors who invest over $150,000 and their investment does not exceed 10% of their net worth. Accredited investors and corporate and other entity investors are generally exempt from such disclosure requirement. ((§25102(n)(4))</li>
      <li><u>Restriction on Resale</u>. The securities are subject to the same restriction on resale as is applicable to the Regulation D safe harbors. </li>
    </ul>Proposed offerings that qualify for the Section 25102(n) safe harbor may be announced to the public in writing. Such announcement may include: a brief description of the business of the issuer, the geographic location of the issuer and its business, and the price of the security to be issued, or, if the price is not known, the method of its determination or the probable price range as specified by the issuer, and the aggregate offering price.
    `,
    NOTE_16: `You have indicated that the company is not going to advertise its offering and plans to limit stock sales to no more than 35 non-accredited investors. California companies that qualify for the Rule 504 safe harbor must also satisfy California securities law requirements. Section 25102(f) provides an exemption from California registration for companies meeting the following requirements:
    <ul>
      <li>Sales of the security are not made to more than 35 non-accredited investors, including persons not in this state.  </li>
      <li>All purchasers either have a preexisting personal or business relationship with the offeror or any of its partners, officers, directors or controlling persons, or managers, or by reason of their business or financial experience or the business or financial experience of their professional advisers who are unaffiliated with and who are not compensated by the issuer or any affiliate or selling agent of the issuer, directly or indirectly, could be reasonably assumed to have the capacity to protect their own interests in connection with the transaction.</li>
      <li>Each purchaser represents that the purchaser is purchasing for the purchaser’s own account (or a trust account if the purchaser is a trustee) and not with a view to or for sale in connection with any distribution of the security.</li>
      <li>The offer and sale of the security is not accomplished by the publication of any advertisement.</li>
    </ul>If you believe that your offering will comply with Section 25102(f) and you wish to order a subscription agreement, click on the button below. Note that a subscription agreement will help establish your company’s compliance with the requirements of this Rule.`,
    NOTE_17: `You have indicated that the company is not going to advertise its offering and may make stock sales to more than 35 non-accredited investors.  Section 25102(n) of the California Corporations Code an exemption from California registration for companies meeting the following requirements:
    <br />
    <ul>
      <li><u>Issuer Requirements</u>. The issuer must be an entity formed in California or a foreign corporation if a majority of its voting securities are held by California residents and the average of its percentages of property, payroll, and sales in California, as determined for state tax purposes, is greater than 50%. The issuer may not be a “blind pool” issuer (referred to as “blank check” company under federal law) or an investment company subject to the Investment Company Act of 1940. (§25102(n)(1))</li><br/>
      <li><u>Investor Requirements</u>. Investors must be “qualified purchasers” or other persons the issuer reasonably believes, after reasonable inquiry, to be “qualified purchasers”. A “qualified purchaser” includes an individual who, with his or her spouse, has a net worth of at least $250,000 and income of over $100,000 or who has a net worth of at least $500,000 with no income requirement, provided that the issuer has only one class of voting common stock. (See Section 25102(n)(2) for a complete definition of “qualified purchasers”.) Each investor must represent that the investor is purchasing the security for the investor's own account and not with a view to or for sale in connection with a distribution of the security. (§25102(n)(3))</li><br/>
      <li><u>Disclosure Requirements</u>. Individual investors (and entities formed by individuals for the purpose of investing) must receive a disclosure statement unless they are sophisticated investors who invest over $150,000 and their investment does not exceed 10% of their net worth. Accredited investors and corporate and other entity investors are generally exempt from such disclosure requirement. ((§25102(n)(4)) </li>
    </ul>Proposed offerings that qualify for the Section 25102(n) safe harbor may be announced to the public in writing. Such announcement may include: a brief description of the business of the issuer, the geographic location of the issuer and its business, and the price of the security to be issued, or, if the price is not known, the method of its determination or the probable price range as specified by the issuer, and the aggregate offering price. 
    `,
    NOTE_18: `You have indicated that the company is not going to advertise its offering and plans to limit stock sales to no more than 35 non-accredited investors. California companies that qualify for the Intrastate Exemption must also satisfy California securities law requirements. Section 25102(f) provides an exemption from California registration for companies meeting the following requirements:
    <br/>
    <ul>
      <li>Sales of the security are not made to more than 35 non-accredited investors, including persons not in this state.  </li>
      <li>All purchasers either have a preexisting personal or business relationship with the offeror or any of its partners, officers, directors or controlling persons, or managers, or by reason of their business or financial experience or the business or financial experience of their professional advisers who are unaffiliated with and who are not compensated by the issuer or any affiliate or selling agent of the issuer, directly or indirectly, could be reasonably assumed to have the capacity to protect their own interests in connection with the transaction.</li>
      <li>Each purchaser represents that the purchaser is purchasing for the purchaser’s own account (or a trust account if the purchaser is a trustee) and not with a view to or for sale in connection with any distribution of the security.</li>
      <li>The offer and sale of the security is not accomplished by the publication of any advertisement.</li>
    </ul>`,
    LINK_3_1: `<div class='text-center' style='text-decoration: underline'>Crowdfunding Safe Harbor</div>
    The crowdfunding regulations enable eligible companies to offer and sell securities to non-accredited investors and permit certain advertising. These regulations:
    <ul>
      <li>require all crowdfunding offerings to take place online through an SEC-registered intermediary, either a broker-dealer or a funding portal (15 USC §77d(a)(6)(C); 17 CFR §227.400-404);</li>
      <li>permit a company to raise a maximum aggregate amount of $1,070,000 through crowdfunding offerings in a 12-month period (17 CFR §227.100(a)(1));</li>
      <li>limit the amount individual investors can invest across all crowdfunding offerings in a 12-month period (17 CFR §227.100(a)(2)); and</li>
      <li>require disclosure of information in filings with the SEC and to investors and the intermediary facilitating the offering.”(<a href='https://www.sec.gov/smallbusiness/exemptofferings/regcrowdfunding'>https://www.sec.gov/smallbusiness/exemptofferings/regcrowdfunding</a> (citations added); 17 CFR §227.201-203)</li>
    </ul>
    Securities purchased in a crowdfunding transaction generally cannot be resold for one year. 
    To be eligible for a crowdfunding offering, an issuer:
    <ul>
      <li>may not be subject to the "bad actor" disqualification (17 CFR §227.503);</li>
      <li>may not be an investment company (17 CFR §227.100(b)(3)); and</li>
      <li>may not be a blank check company. (17 CFR §227.100(b)(6)).</li>
    </ul>`,
    LINK_3_2: `The use of general solicitation or advertising disqualifies an offering from the safe harbor of Rule 506(b) (17 CFR §230.506(b)(1) and 17 CFR §230.502(c)) The Rule 504 safe harbor is also not available for companies planning to use advertising or general solicitation to promote stock sales to non-accredited investors except in states that “provide for the registration of the securities and require the public filing and delivery to investors of a substantive disclosure document before sale” (17 CFR §230.504(b)(1)(i)) or in states with no such requirements if the stock has been registered in a state with such registration and disclosure requirements and the disclosure document is delivered before sale to all purchasers. (Id.) Since California has no exemption permitting advertising to promote stock sales to non-accredited investors, Rule 504 cannot be relied upon in California to authorize advertising to promote stock sales to non-accredited investors.`
  },
  step: 8,
  nodes: [
    {
      id: 'yesno_2',
      kind: 'YesNo',
      content: {
        question: 'Will the company use general solicitation or advertising to market the securities?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'final_3' },
          { kind: 'choice', label: 'No', next: 'yesno_4' },
        ],
        note: {
          title: 'General Solicitation or Advertising',
          attach: ['NOTE_2']
        }
      },
    },
    {
      id: 'final_3',
      kind: 'Final',
      content: {
        title: 'Disclosure Requirement and Advertising',
        attach: ['NOTE_3']
      }
    },
    {
      id: 'yesno_4',
      kind: 'YesNo',
      content: {
        question: 'Does the company plan to limit stock sales to no more than 35 non-accredited investors?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'yesno_5' },
          { kind: 'choice', label: 'No', next: 'yesno_7' },
        ],
        store: 'IS_LIMITED'
      },
    },
    {
      id: 'yesno_5',
      kind: 'YesNo',
      content: {
        question: 'Does the company plan to provide detailed disclosure documents to each non-accredited investor?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'final_6' },
          { kind: 'choice', label: 'No', next: 'yesno_7' },
        ]
      },
    },
    {
      id: 'final_6',
      kind: 'Final',
      content: {
        title: 'Rule 506(b)',
        attach: ['NOTE_4']
      }
    },
    {
      id: 'yesno_7',
      kind: 'YesNo',
      content: {
        question: 'Is the company an investment company?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'final_8' },
          { kind: 'choice', label: 'No', next: 'yesno_9' },
        ],
        note: {
          title: 'Investment Companies',
          attach: ['NOTE_5']
        }
      },
    },
    {
      id: 'final_8',
      kind: 'Final',
      content: {
        title: 'Investment Companies – Application',
        attach: ['NOTE_6']
      }
    },
    {
      id: 'yesno_9',
      kind: 'YesNo',
      content: {
        question: 'Does the company "do business" within the State of California?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'yesno_10' },
          { kind: 'choice', label: 'No', next: 'yesno_13' },
        ],
        note: {
          title: '“Doing Business”',
          attach: ['NOTE_7']
        }
      },
    },
    {
      id: 'yesno_10',
      kind: 'YesNo',
      content: {
        question: 'Will the company sell its securities only to California residents?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'yesno_11' },
          { kind: 'choice', label: 'No', next: 'yesno_13' },
        ]
      },
    },
    {
      id: 'yesno_11',
      kind: 'YesNo',
      content: {
        question: 'Does the company have its principal place of business in California?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'description_12' },
          { kind: 'choice', label: 'No', next: 'yesno_13' },
        ],
        note: {
          title: 'Principal Place of Business',
          attach: ['NOTE_8']
        }
      },
    },
    {
      id: 'description_12',
      kind: 'Description',
      content: {
        question: 'California Intrastate Exemption',
        fields: [{ kind: 'attach', value: 'NOTE_9' }],
        next: 'progress_22'
      }
    },
    {
      id: 'yesno_13',
      kind: 'YesNo',
      content: {
        question: 'Is the company subject to the "bad actor" ban?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'final_14' },
          { kind: 'choice', label: 'No', next: 'yesno_15' },
        ],
        note: {
          title: 'Bad Actor Ban',
          attach: ['NOTE_10']
        }
      },
    },
    {
      id: 'final_14',
      kind: 'Final',
      content: {
        title: 'Bad Actor Ban – Application',
        attach: ['NOTE_11']
      }
    },
    {
      id: 'yesno_15',
      kind: 'YesNo',
      content: {
        question: 'Is the company a blank check company (referred to in California as a "blind pool company")?',
        fields: [
          { kind: 'choice', label: 'Yes', next: 'final_17' },
          { kind: 'choice', label: 'No', next: 'description_16' },
        ],
        note: {
          title: 'Blank Check Company – Definition',
          attach: ['NOTE_12']
        }
      },
    },
    {
      id: 'description_16',
      kind: 'Description',
      content: {
        question: 'Rule 504',
        fields: [{ kind: 'attach', value: 'NOTE_13' }],
        next: 'progress_18'
      }
    },
    {
      id: 'final_17',
      kind: 'Final',
      content: {
        title: 'Blank Check Companies – Application',
        attach: ['NOTE_14']
      }
    },
    {
      id: 'progress_18',
      kind: 'Progress',
      content: {
        fields: [{ message: 'Analyzing California exemptions...', timeout: 4000 }],
        next: 'action_19'
      },
    },
    {
      id: 'action_19',
      kind: 'Action',
      content: {
        kind: 'IS_VALUE',
        store: 'IS_LIMITED',
        value: 'Yes',
        next: ['final_21', 'final_20']
      }
    },
    {
      id: 'final_20',
      kind: 'Final',
      content: {
        title: 'Rule 1001 and Section 25102(n)',
        attach: ['NOTE_15']
      }
    },
    {
      id: 'final_21',
      kind: 'Final',
      content: {
        title: 'Rule 504 and Section 25102(f)',
        attach: ['NOTE_16']
      }
    },
    {
      id: 'progress_22',
      kind: 'Progress',
      content: {
        fields: [{ message: 'Analyzing California exemptions...', timeout: 4000 }],
        next: 'action_23'
      },
    },
    {
      id: 'action_23',
      kind: 'Action',
      content: {
        kind: 'IS_VALUE',
        store: 'IS_LIMITED',
        value: 'Yes',
        next: ['final_25', 'final_24']
      }
    },
    {
      id: 'final_24',
      kind: 'Final',
      content: {
        title: 'Section 25102(n)',
        attach: ['NOTE_17']
      }
    },
    {
      id: 'final_25',
      kind: 'Final',
      content: {
        title: 'California Intrastate Exemption and Section 25102(f)',
        attach: ['NOTE_18']
      }
    },
  ],
}
