export default {
  name: 'incorporate',
  description: 'Under Construction',
  start: 'final',
  kind: 'Topic',
  nodes: [
    {
      id: 'final',
      kind: 'Final',
      content: {
        title: 'Under Construction',
        message: 'We are working on this yet. Thank you.'
      }
    },
  ]
}
