/* eslint-disable */
export default {
  name: 'ca_professional_corporation_bylaws',
  description: 'Creating California Professional Corp. Bylaws',
  start: 'description_0',
  kind: 'Form',
  step: 5,
  attach: {
    NOTE_1: `This form is designed for use by a small, privately held California professional
    corporation. The following additional assumptions have been made about the
    corporation:
    <ul>
      <li>The Articles of Incorporation do not vary the statutory requirement that the majority of shareholders constitutes a quorum for shareholder meetings (Corp. code §602(a)) and that a majority of directors constitutes a quorum for directors’ meetings. (Corp. code §307(a)(7)).</li>
      <li>The Articles of Incorporation do not specify (and the Corporation does not wish that the Bylaws specify) additional persons entitled to call a special meeting of shareholders. (Corp code §600).</li>
      <li>The Corporation is not a statutory close corporation.</li>
      <li>Except for those qualifications arising from being a professional corporation, the Corporation does not wish to establish any qualifications for directors, such as the requirement that a director be a shareholder. (See Corp. code §212(b)(4)).</li>
      <li>The Corporation does not wish to compensate the Directors for serving as directors but wishes them to receive reimbursement for their reasonable out of pocket expenses.</li>
      <li>The Corporation does not own any insurance company from which it acquires liability insurance to protect its directors or agents. (Corp code §317).</li>
      <li>The Corporation does not wish to allow the Board of Directors to amend the Bylaws. (Corp. code §211).</li>
    </ul>`,
    NOTE_2: `California professional corporations are required to have at least three directors, except in the following cases:
    <ul>
      <li>Before any shares are issued, the corporation may have one or two directors.</li>
      <li>So long as the corporation has only one shareholder, the corporation may have one or two directors. If the corporation has only director, then such director shall be the sole shareholder and shall also serve as the president and treasurer of the corporation, but the other officers need not be licensed persons.</li>
      <li>So long as the corporation has only two shareholders, the corporation may have two directors who shall be such shareholders. The two shareholders between them shall fill the offices of president, vice president, secretary and treasurer.</li>
    </ul>(See Corp. code §212 and §13403).`,
  },
  nodes: [
    {
      id: 'description_0',
      kind: 'Description',
      content: {
        question: 'This form is designed for use by a small, privately held California professional corporation.',
        fields: [{
          value: 'Click <strong>ⓘ</strong> for a more detailed explanation of this form and its limitations.'
        }],
        note: {
          title: 'About this Form',
          attach: ['NOTE_1'],
        },
        next: 'single_1',
      },
    },
    {
      id: 'single_1',
      kind: 'Single',
      content: {
        question: 'What is the profession practiced by the corporation?',
        fields: [
          { kind: 'choice', label: 'Accountancy' },
          { kind: 'choice', label: 'Architecture' },
          { kind: 'choice', label: 'Chiropractic' },
          { kind: 'choice', label: 'Dentistry' },
          { kind: 'choice', label: 'Law' },
          { kind: 'choice', label: 'Licensed Clinical Social Work' },
          { kind: 'choice', label: 'Medicine' },
          { kind: 'choice', label: 'Optometry' },
          { kind: 'choice', label: 'Psychology' },
          { kind: 'choice', label: 'Other', next: 'final_2' },
          // { kind: 'text', label: 'Other', emptyWhenSelect: true, next: 'final_1' },
        ],
        store: "profession",
        next: 'input_1',
      },
    },
    {
      id: 'input_1',
      kind: 'Input',
      content: {
        question: 'What is the name of the corporation?',
        fields: [{ kind: 'text', store: 'name_of_corporation' }],
        next: 'input_2',
      },
    },
    {
      id: 'input_2',
      kind: 'Input',
      content: {
        question:
          'The corporation must hold an annual shareholders meeting. On what day and month (each year) shall this meeting be held?<br/><small>(Note that the board of directors may change this date by notice to the shareholders.)</small>',
        fields: [{ kind: 'text', store: 'annual_meeting_date' }],
        next: 'input_3',
      },
    },
    {
      id: 'input_3',
      kind: 'Input',
      content: {
        question:
          "How many directors will serve on the corporation's board of directors?",
        description:
          '(Note that subject to certain exceptions, a corporation must have at least three directors. Click <strong>ⓘ</strong> to view the exceptions.)',
        fields: [{ kind: 'text', store: 'number_of_directors' }],
        note: {
          title: 'Number of Directors',
          attach: ['NOTE_2'],
        },
        next: 'action_1',
      },
    },
    {
      id: "action_1",
      kind: 'Action',
      content: {
        // question: "a profession (other than engineering or architecture), personal services, brokerage or finance?",
        kind: 'IN_ARRAY',
        store: 'profession',
        value: [
          'Accountancy',
          'Architecture',
          'Law',
        ],
        next: ["final_1", "final_0"]
      }
    },
    {
      id: 'final_0',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'ca_professional_corporation_bylaws_healthcare',
      },
    },
    {
      id: 'final_1',
      kind: 'Final',
      content: {
        kind: 'Form',
        form: 'ca_professional_corporation_bylaws_non_healthcare',
      },
    },
    {
      id: 'final_2',
      kind: 'Final',
      content: {
        title: 'Creating California Professional Corp. Bylaws',
        message:
          'Bylaws for this profession are under construction.'
      }
    }
  ],
}
