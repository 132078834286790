// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:5869a338-2e68-43c3-b049-3bdb985f8114",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_izMbHjOPZ",
    "aws_user_pools_web_client_id": "53k2pf8lku8af72cjnoid4483s",
    "aws_content_delivery_bucket": "legalmaven.com",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2qqerjo9f1h0s.cloudfront.net"
};


export default awsmobile;
